


























import { Component, Prop, Vue }                     from 'vue-property-decorator'


import { ProjectResource } from '@/models/projects/ProjectResource'

@Component
export default class SubNavigation extends Vue {

  @Prop()
  private readonly project!: ProjectResource

  private get canEditProject(): boolean {
    return this.project.canPerformAction('can_edit_project')
  }
}
