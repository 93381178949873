






import { Component, Prop, Vue } from 'vue-property-decorator'

import SubNavigation from '@/views/dashboard/projects/partials/SubNavigation.vue'

import { ProjectResource } from '@/models/projects/ProjectResource'

@Component({
  components: {
    SubNavigation
  }
})
export default class ProjectsDetailIndex extends Vue {

  @Prop()
  private readonly project!: ProjectResource

}
